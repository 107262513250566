<template>
  <div>
    <el-dialog
      title="变更自提点"
      :visible.sync="centerDialogVisible"
      v-if="centerDialogVisible"
      width="50%"
      class="dialog"
      center
    >
      <div class="contents deadline">
        <el-form
          :model="cityForm"
          :rules="cityrules"
          ref="cityForm"
          label-width="170px"
          class="demo-cityForm"
        >
          <el-form-item label="变更用户手机号" prop="mobile">
            <div>
              <el-input
                v-model="cityForm.mobile"
                placeholder="请输入变更用户手机号"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="订单编号" prop="order_no">
            <div>
              <el-input
                v-model="cityForm.order_no"
                placeholder="请输入订单编号"
                clearable
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item label="变更自提点" prop="delivery_id">
            <div>
              <el-select
                v-model="cityForm.delivery_id"
                clearable
                placeholder="选择变更自提点"
              >
                <el-option
                  :label="item.name"
                  v-for="item in option"
                  :disabled="item.is_current != 0"
                  :key="item.id"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="变更原因" prop="reason">
            <div>
              <el-input
                v-model="cityForm.reason"
                maxlength="200"
                show-word-limit
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 6 }"
                placeholder="请输入变更原因"
                clearable
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="cityConfirm">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      centerDialogVisible: false,
      cityForm: {
        order_id: "", //	number	订单id
        delivery_id: "", //	number	自提点ID
        order_no: "", //	string	订单号
        mobile: "", //	string	用户手机号
        reason: "", //	string	原因 最大200字
      },
      option: [],
      cityrules: {
        order_no: [
          { required: true, message: "请输入订单编号", trigger: "blur" },
        ],
        reason: [
          { required: true, message: "请输入变更原因", trigger: "blur" },
        ],
        mobile: [
          {
            required: true,
            message: "请输入变更用户手机号",
            // blur和change事件触发检验
            trigger: ["blur", "change"],
          },
          // 正则判断为字母或数字
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            // 正则检验前先将值转为字符串
            transform(value) {
              return String(value);
            },
            message: "该手机号码不符合规范",
            trigger: ["blur"],
          },
        ],
        delivery_id: [
          {
            required: true,
            message: "请输入变更自提点",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },

  methods: {
    hqinfo(e) {
        this.cityForm = {
            order_id: "", //	number	订单id
        delivery_id: "", //	number	自提点ID
        order_no: "", //	string	订单号
        mobile: "", //	string	用户手机号
        reason: "", //	string	原因 最大200字
      };
      this.cityForm.order_id = e.id;
      this.centerDialogVisible = true;
      this.hqoptin(e);
    },
    hqoptin(e) {
      this.$api.general.orderdeliveryList({ order_id: e.id }).then((res) => {
        console.log(res, "1111111111111");
        this.option = res.data;
      });
    },
    cityConfirm() {
      this.$refs.cityForm.validate((valid) => {
        if (valid) {
          this.$api.general.orderdeliverychange(this.cityForm).then((res) => {
            this.$emit("cityConfirm");
            this.centerDialogVisible = false;
            this.$message({
              type: "success",
              message: "变更自提点成功",
            });
          });
        }
      });
    },
  },
};
</script>
  
  <style>
</style>