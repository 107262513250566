var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cancelOfOrder" },
    [
      _vm.centerDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "dialog",
              attrs: {
                title: "取消订单",
                visible: _vm.centerDialogVisible,
                width: "50%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.centerDialogVisible = $event
                },
              },
            },
            [
              _c("el-alert", {
                attrs: {
                  title: "取消订单之后将直接给用户退款，请谨慎操作！！！ ",
                  type: "error",
                  closable: false,
                },
              }),
              _c(
                "div",
                { staticClass: "contents deadline" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "cityForm",
                      staticClass: "demo-cityForm",
                      attrs: {
                        model: _vm.cityForm,
                        rules: _vm.cityrules,
                        "label-width": "170px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "变更用户手机号", prop: "mobile" } },
                        [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入变更用户手机号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.cityForm.mobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.cityForm, "mobile", $$v)
                                  },
                                  expression: "cityForm.mobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单编号", prop: "order_no" } },
                        [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入订单编号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.cityForm.order_no,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.cityForm, "order_no", $$v)
                                  },
                                  expression: "cityForm.order_no",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "变更原因", prop: "reason" } },
                        [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "200",
                                  "show-word-limit": "",
                                  type: "textarea",
                                  autosize: { minRows: 2, maxRows: 6 },
                                  placeholder: "请输入变更原因",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.cityForm.reason,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.cityForm, "reason", $$v)
                                  },
                                  expression: "cityForm.reason",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "变更证据", prop: "reason_img" } },
                        [
                          _c(
                            "el-upload",
                            {
                              class: { hide: _vm.url.length == 5 },
                              attrs: {
                                "element-loading-text": "正在上传...",
                                action: "#",
                                "on-preview": _vm.handlePictureCardPreview,
                                "file-list": _vm.url,
                                "list-type": "picture-card",
                                "http-request": (file) => {
                                  return _vm.uploadFile(file, "url")
                                },
                                "before-remove": (file, fileList) => {
                                  return _vm.deleteFile(file, fileList, "url")
                                },
                                limit: 5,
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-plus",
                                attrs: { slot: "trigger" },
                                slot: "trigger",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.centerDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.cancelOfOrder },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }