var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.centerDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "dialog",
              attrs: {
                title: "变更自提点",
                visible: _vm.centerDialogVisible,
                width: "50%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.centerDialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "contents deadline" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "cityForm",
                      staticClass: "demo-cityForm",
                      attrs: {
                        model: _vm.cityForm,
                        rules: _vm.cityrules,
                        "label-width": "170px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "变更用户手机号", prop: "mobile" } },
                        [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入变更用户手机号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.cityForm.mobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.cityForm, "mobile", $$v)
                                  },
                                  expression: "cityForm.mobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单编号", prop: "order_no" } },
                        [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入订单编号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.cityForm.order_no,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.cityForm, "order_no", $$v)
                                  },
                                  expression: "cityForm.order_no",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "变更自提点", prop: "delivery_id" } },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "选择变更自提点",
                                  },
                                  model: {
                                    value: _vm.cityForm.delivery_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.cityForm, "delivery_id", $$v)
                                    },
                                    expression: "cityForm.delivery_id",
                                  },
                                },
                                _vm._l(_vm.option, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.name,
                                      disabled: item.is_current != 0,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "变更原因", prop: "reason" } },
                        [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "200",
                                  "show-word-limit": "",
                                  type: "textarea",
                                  autosize: { minRows: 2, maxRows: 6 },
                                  placeholder: "请输入变更原因",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.cityForm.reason,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.cityForm, "reason", $$v)
                                  },
                                  expression: "cityForm.reason",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.centerDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.cityConfirm },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }